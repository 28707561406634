import React from 'react'
import './pageNotFound.css';
import alexanderRadio from './AlexanderRadio.png';
import { Link } from 'react-router-dom';

function NotFound() {
    return (

      <div className="App">
        <div className="Background">
          <nav>
            <Link to="/" className="homeReturn">Home</Link>
            <Link to="/Contact" className="contactReturn">Contact</Link>
          </nav>
          <div className="AboutError">
            <div className="RadioShadow"><img className="AlexanderRadio" src={alexanderRadio} alt='alexander radio' /></div>
            <div className="The404">Error 404!</div>
            <div className="Explain404">Page Not Found!</div>
          </div>
        </div>
      </div>
    )
}

export default NotFound;
