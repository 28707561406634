import './App.css';
import alexanderHelium from './AlexanderHelium.jpeg';
import { Link } from 'react-router-dom';

function App() {
  return (
    <div className="App">
      <div className="Background">
        <nav>
          <Link to="/" className="homeReturn">Home</Link>
          <Link to="/Contact" className="contactReturn">Contact</Link>
        </nav>
        <div className="About">
          <img className="AlexanderHelium" src={alexanderHelium} alt="Alexander" />
          <div className="TextBox">
            <p>Hello There!</p>
            <p>My Name is Alexander,</p>
            <p>I am a student with a massive space obsession that hasn't left me since 2nd grade.  I have always loved learning how things work, especially rockets and spacecraft!  Some things that interest me, in particular, are Rocket Science, Planetary Science, Space Exploration, Astronomy, Teaching, Weather Balloons, Web Development, Ham-Radio, Cryptocurrency, CubeSats, Coding, Coffee, etc... If I had to name everything that interests me, I would be here a very long time. </p>
            <p>Feel free to check out the footage from my latest weather balloon flight <a href="https://youtu.be/NQqaMOXl9LY?feature=shared">here</a></p>
            <p>Feel free to check out my Tik Tok <a href="https://www.tiktok.com/@saturnianhorizon">here</a></p>
            <p>Feel free to check out a cryptocurrency I just created <a href="https://badinvestmentcoin.com/">badinvestmentcoin.com</a></p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
