import React from 'react'
import './contactPage.css';
import alexanderRadio from './AlexanderRadio.png';
import { Link } from 'react-router-dom';

function Contact() {
    return (

      <div className="App">
        <div className="Background">
          <nav>
            <Link to="/" className="homeReturn">Home</Link>
            <Link to="/Contact" className="contactReturn">Contact</Link>
          </nav>
          <div className="AboutContact">
          <img className="AlexanderRadio2" src={alexanderRadio} alt='alexander radio' />

          <div className="Email1">Email: <a href="mailto:Alexander@alexanderbuick.com">Alexander@alexanderbuick.com</a></div>
          <div className="Email2">For Media Inquiries: <a href="mailto:Media@AlexanderBuick.com">Media@alexanderBuick.com</a></div>
          </div>
        </div>
      </div>
    )
}

export default Contact;
